import React from "react";
import "../assets/style/globalStyle.scss";
import NavBar from "../components/navBarSite";

const wikiPage = () => {
  return (
    <div style={{ margin: 0, padding: 0, paddingTop: 120 }}>
      <NavBar></NavBar>
      <div>
        <p>Page Wiki</p>
      </div>
    </div>
  );
};

export default wikiPage;
